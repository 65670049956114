import { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import SubMenu from "./components/Submenu";
import Sidebar from "./components/Sidebar";
import ServiceVDP from "./components/ServiceVDP";
import Footer from "./components/Footer";

function App() {
  const [click, setClick] = useState(false);
  const [state, setState] = useState(false);
  return (
    <div className="App">
      <Header
        setClick={setClick}
        click={click}
        state={state}
        setState={setState}
      />
      {state && (
        <SubMenu
          setClick={setClick}
          click={click}
          state={state}
          setState={setState}
        />
      )}
      {click && (
        <Sidebar
          setClick={setClick}
          click={click}
          state={state}
          setState={setState}
        />
      )}

      <ServiceVDP
        setClick={setClick}
        click={click}
        state={state}
        setState={setState}
      />

      <Footer
        setClick={setClick}
        click={click}
        state={state}
        setState={setState}
      />
    </div>
  );
}

export default App;
